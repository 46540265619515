export function setCache(key: string, value: string | [] | {}, sec: number = 31556926) {
    const now = new Date();

    // `item` is an object which contains the original value
    // as well as the time when it's supposed to expire
    const item = {
        data: value,
        expiry: now.getTime() + (sec * 1000)
    };
    return localStorage.setItem(key, JSON.stringify(item));
}

export function getCache(key: string): (any[] | null) {
    const itemStr = localStorage.getItem(key);

    // if the item doesn't exist, return null
    if (!itemStr) {
        return null;
    }

    const item = JSON.parse(itemStr);
    const now = new Date();

    // compare the expiry time of the item with the current time
    if (now.getTime() > item.expiry) {
        // If the item is expired, delete the item from storage
        // and return null
        localStorage.removeItem(key);
        return null;
    }
    return item.data;
}

export function deleteCache(key: string) {
    return localStorage.removeItem(key);
}

export function sizeCache(): string {
    let allStrings = '';
    for (const key in window.localStorage) {
        if (window.localStorage.hasOwnProperty(key)) {
            allStrings += window.localStorage[key];
        }
    }
    return allStrings ? 3 + ((allStrings.length * 16) / (8 * 1024)).toFixed(2) + ' KB' : 'Empty (0 KB)';
}

export function isEmpty(val): Boolean {
    return (val === undefined || val == null || val.length <= 0) ? true : false;
}

export function placeholder(width: number = 0, height: number = 0) {
    return `data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='${width}' height='${height}' viewBox='0 0 ${width} ${height}' fill='none'><rect width='${width}' height='${height}'></rect></svg>`;
}

export function isNumber(n: any): boolean {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0)
}

export function isTouch(): boolean {
    // @ts-ignore
    return ('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator?.msMaxTouchPoints > 0);
}

export function uniqueBy(arr: [], prop) {
    return [...new Map(arr.map(m => [m[prop], m])).values()];
}

export function capitalize(s): string {
    if (typeof s !== 'string') {
        return '';
    }
    return s.charAt(0).toUpperCase() + s.slice(1);
}

export function objectMap(obj, fn) {
    return Object.fromEntries(
        Object.entries(obj).map(
            ([k, v], i) => [k, fn(v, k, i)]
        )
    );
}

export function getRandomInt(min, max) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min); // The maximum is exclusive and the minimum is inclusive
}

export function getClosestColor(mainColor, arr, skip = null) {
    const primary = hexToHsv(mainColor);
    let closest = {i: 0, dist: 9999999};
    arr.forEach((color, i) => {
        if (skip && color === skip) {
            return;
        }
        const compare = hexToHsv(color);
        const distH =
            Math.min(Math.abs(compare.h - primary.h), 360 - Math.abs(compare.h - primary.h)) * 2;
        const distS = Math.abs(compare.s - primary.s);
        const dist = distH + distS;
        if (dist < closest.dist) {
            closest = {i, dist};
        }
    });
    return arr[closest.i];
}

export function hexToHsv(color) {
    let {r, g, b} = hexToRgb(color);
    (r /= 255), (g /= 255), (b /= 255);

    const max = Math.max(r, g, b);
    const min = Math.min(r, g, b);
    let h = max;
    let s = max;
    let v = max;

    const d = max - min;
    s = max == 0 ? 0 : d / max;

    if (max == min) {
        h = 0; // achromatic
    } else {
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0);
                break;
            case g:
                h = (b - r) / d + 2;
                break;
            case b:
                h = (r - g) / d + 4;
                break;
        }

        h /= 6;
    }

    return {h, s, v};
}

export function hexToRgb(hex) {
    const r = parseInt(hex.substr(1, 2), 16);
    const g = parseInt(hex.substr(3, 2), 16);
    const b = parseInt(hex.substr(5, 2), 16);
    return {r, g, b};
}
