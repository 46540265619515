// App main
import 'unfonts.css';
import 'virtual:svg-icons-register';
import '@/css/app.pcss';
import 'swiper/css';
import 'tippy.js/dist/tippy.css';
import Alpine from 'alpinejs';
import intersect from '@alpinejs/intersect';
import collapse from '@alpinejs/collapse';
import {
    capitalize,
    deleteCache,
    getCache,
    getClosestColor,
    getRandomInt,
    isEmpty,
    objectMap,
    placeholder,
    setCache,
    sizeCache,
    uniqueBy
} from './util';

Alpine.plugin(intersect);
Alpine.plugin(collapse);

const main = async () => {
    // Async load the vue module
    // const {default: ScrollOut} = await import('scroll-out');
    await import('lazysizes');
    await import('lazysizes/plugins/bgset/ls.bgset');

    const colors = await import('@/colors.json');

    const {default: dayjs} = await import('dayjs');
    const {default: localizedFormat} = await import('dayjs/plugin/localizedFormat');
    const {default: customParseFormat} = await import('dayjs/plugin/customParseFormat');
    const {default: relativeTime} = await import('dayjs/plugin/relativeTime');
    const {default: isToday} = await import('dayjs/plugin/isToday');
    const {default: updateLocale} = await import('dayjs/plugin/updateLocale');
    const {default: fitty} = await import('fitty');
    await import('dayjs/locale/nb');
    dayjs.locale('nb');
    dayjs.extend(localizedFormat);
    dayjs.extend(relativeTime);
    dayjs.extend(customParseFormat);
    dayjs.extend(isToday);
    dayjs.extend(updateLocale);
    dayjs.updateLocale('nb', {
        weekdaysShort: ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør']
    });
    return {dayjs, fitty, colors};
};

window['capitalize'] = capitalize;
window['uniqueBy'] = uniqueBy;
window['placeholder'] = placeholder;
window['getRandomInt'] = getRandomInt;
// Execute async function
main().then(modules => {
    document.documentElement.classList.remove('no-js');
    /* modules.ScrollOut({
         once: true
     });*/
    const colors = modules.colors;

    window['colors'] = colors;
    window['dayjs'] = modules.dayjs;
    window['fitty'] = modules.fitty;

    window['app'] = {
        isDone(start, slutt = null) {
            let tidspunkt = slutt ? modules.dayjs(slutt) : modules.dayjs(start).add(3, 'hour');
            return modules.dayjs().isAfter(tidspunkt);
        },
        circleShapes: [
            'sommerfugl',
            'firkantMedHull',
            'liggendeStrek',
            'fyll',
            'diamant',
            'hullIMidten',
            'polseMedBrod',
            'sikksakk',
            'vanlig'
        ],
        colorMatrix: {
            bg: [
                colors.red['300'],
                colors.red['500'],
                colors.green['300'],
                colors.green['500'],
                colors.blue['300'],
                colors.blue['500'],
                colors.orange['300'],
                colors.orange['500'],
                colors.purple['300'],
                colors.purple['500'],
                colors.yellow['300'],
                colors.yellow['500']
            ],
            fg: [
                colors.red['300'],
                colors.red['500'],
                colors.green['300'],
                colors.green['500'],
                colors.blue['300'],
                colors.blue['500'],
                colors.orange['300'],
                colors.orange['500'],
                colors.purple['300'],
                colors.purple['500'],
                colors.yellow['300'],
                colors.yellow['500']
            ]
        },
        mndStil: [
            {
                // 0
                bg: colors.orange['300'],
                fg: colors.orange['500'],
                ac: colors.orange['500'],
                svgForm: 'sikksakk'
            },
            {
                // 1
                bg: colors.purple['300'],
                fg: colors.red['300'],
                ac: colors.purple['500'],
                svgForm: 'liggendeStrek'
            },
            {
                // 2
                bg: colors.blue['300'],
                fg: colors.green['500'],
                ac: colors.blue['500'],
                svgForm: 'vanlig'
            },
            {
                // 3
                bg: colors.yellow['500'],
                fg: colors.orange['300'],
                ac: colors.yellow['300'],
                svgForm: 'firkantMedHull'
            },
            {
                // 4
                bg: colors.red['300'],
                fg: colors.green['500'],
                ac: colors.red['500'],
                svgForm: 'fyll'
            },
            {
                // 5
                bg: colors.purple['300'],
                fg: colors.orange['300'],
                ac: colors.purple['500'],
                svgForm: 'diamant'
            },
            {
                // 6
                bg: colors.blue['300'],
                fg: colors.yellow['500'],
                ac: colors.blue['500'],
                svgForm: 'sikksakk'
            },
            {
                // 7
                bg: colors.yellow['300'],
                fg: colors.purple['300'],
                ac: colors.yellow['500'],
                svgForm: 'liggendeStrek'
            },
            {
                // 8
                bg: colors.orange['300'],
                fg: colors.orange['500'],
                ac: colors.orange['500'],
                svgForm: 'vanlig'
            },
            {
                // 9
                bg: colors.purple['300'],
                fg: colors.orange['300'],
                ac: colors.purple['500'],
                svgForm: 'firkantMedHull'
            },
            {
                // 10
                bg: colors.blue['300'],
                fg: colors.red['300'],
                ac: colors.blue['500'],
                svgForm: 'fyll'
            },
            {
                // 11
                bg: colors.red['300'],
                fg: colors.red['500'],
                ac: colors.red['500'],
                svgForm: 'diamant'
            }
        ],
        resizeElms: [],
        hentSession() {
            return {
                user: {
                    isGuest: true
                },
                csrf: null,
                admin: {
                    show: localStorage.getItem('showAdmin') === 'true',
                    lenke: null,
                    visErrorSkjema: false,
                    url: '/admin'
                },
                init: async function () {
                    await fetch('/admin/actions/users/session-info', {
                        headers: {
                            Accept: 'application/json',
                            'Content-Type': 'application/json'
                        }
                    })
                        .then(res => res.json())
                        .then(json => {
                            this.user = json;
                            this.csrf = json.csrfTokenValue;
                            if (!this.user.isGuest) {
                                try {
                                    // @ts-ignore
                                    Sentry.setUser({
                                        username: this.user.username,
                                        email: this.user.email
                                    });
                                } catch (error) {
                                    console.warn(error);
                                }
                                this.adminbar();
                            }
                        })
                        .catch(error => {
                            console.error('Error:', error);
                        });

                    this.$watch('editId', value => {
                        // console.log('editId', value)
                        this.hentEdit(value);
                    });

                    this.$watch('admin.show', value => {
                        localStorage.setItem('showAdmin', value);
                    });

                    if (this.editId) {
                        this.hentEdit(this.editId);
                    }
                },
                async adminbar() {
                    const {default: adminbar} = await import('@/template/adminbar.html?raw');
                    let elem = document.createElement('div');
                    elem.innerHTML = adminbar;
                    document.body.appendChild(elem);
                },
                async hentEdit(id = null) {
                    if (!this.csrf || this.user.isGuest || id == null) {
                        return false;
                    }

                    this.admin.lenke =
                        (await (await fetch('/api/edit?ids=' + id)).json()).data[0] || null;
                }
            };
        },
        initForm(farge = null, form = null) {
            return {
                farge: farge,
                form: form,
                classes: '',
                async svg(classes = '', last = false) {
                    this.classes = classes;
                    const {default: svg} = await import('@/template/svgForm.html?raw');
                    let elem = document.createElement('div');
                    elem.innerHTML = svg;
                    if (last) {
                        this.$el.append(elem.firstChild);
                    } else {
                        this.$el.prepend(elem.firstChild);
                    }
                },
                setRandom(i = 0, delay = 200) {
                    // console.log(this.random);
                    if (typeof this.random === 'undefined') {
                        const index = getRandomInt(0, 11);
                        this.farge = window.app.getStil(index).fg;
                        this.form = window.app.getStil(index).svgForm;
                        return
                    }
                    setTimeout(() => {
                        this.farge = window.app.getStil(this.random[i - 1] % 11).fg;
                        this.form = window.app.getStil(this.random[i - 1] % 11).svgForm;
                    }, getRandomInt(0, 9) * delay)
                }
            };
        },
        initQuickShop() {
            return {
                arrId: null,
                arrangement: null,
                setup() {
                    console.log('quickinit');
                    this.$watch('arrId', value => {
                        if (value) {
                            this.hent(value);
                        } else {
                            setTimeout(() => {
                                this.arrangement = null;
                            }, 300);
                        }
                    });
                },
                async hent(id) {
                    let arr = (await (await fetch(`/api/arrangement/${id}`)).json()).data || null;
                    arr.events = arr.events.map(event => {
                        event.done = window.app.isDone(event.start, event.end);
                        return event;
                    });

                    this.arrangement = arr;
                }
            };
        },
        initArrangement(sal = null, kategori = null, limit = 12) {
            return {
                arrangement: [],
                ekstraIndex: null,
                laster: true,
                filter: {
                    kategori: kategori || [],
                    sal: sal || [],
                    mnd: null,
                    tekst: '',
                    limit: limit
                },
                async hent() {
                    const key = 'arrangement';
                    let arrangement = getCache(key);
                    if (!arrangement) {
                        let arr = (await (await fetch('/api/arrangement')).json()).data || null;
                        arrangement = arr
                            .map(item => {
                                item.events = item.events.filter(event => {
                                    return !window.app.isDone(event.start, event.end);
                                });
                                item.sort = item.events.map(event => modules.dayjs(event.start).unix())[0] ?? null;
                                return item;
                            })
                            .filter(item => item.events.length !== 0);
                        setCache(key, arrangement, 30 * 60);
                        //TODO: slett denne etterhvert
                        deleteCache('arrangement:fix');
                        console.log('storage size', sizeCache());
                    }
                    this.laster = false;
                    Object.freeze(arrangement);
                    this.arrangement = arrangement;
                    // console.log(arrangement);
                    const random = [...Array(arrangement.length)]
                        .map((item, i) => i)
                        .sort((a, b) => 0.5 - Math.random());
                    this.ekstraIndex = random.slice(0, 6);
                },
                listMnd() {
                    let mnd = this.arrangement
                        .reduce((arr, item) => {
                            const dates = item.events.map(event =>
                                modules.dayjs(event.start).format('YYYY-MM')
                            );
                            return [...arr, ...dates];
                        }, [])
                        .sort((a, b) => {
                            return modules.dayjs(a, 'YYYY-MM').unix() - modules.dayjs(b, 'YYYY-MM').unix();
                        });
                    mnd = Array.from(new Set(mnd));
                    return mnd;
                },
                listKat() {
                    const cat = this.arrangement.reduce(
                        (arr, item) => [...arr, ...item.category],
                        []
                    );
                    return uniqueBy(cat, 'value').sort((a, b) => a.value.localeCompare(b.value));
                },
                listSal() {
                    const sal = this.arrangement.reduce((arr, item) => {
                        const saler = item.events.map(event => event.hall);
                        return [...arr, ...saler];
                    }, []);
                    return uniqueBy(sal, 'value');
                },
                events(arr: object = null) {
                    const arrangement = arr ?? this.sortert();
                    return arrangement
                        .reduce((all, arr) => {
                            const events = arr.events.map(event => {
                                let newEvent = {...arr, ...event};
                                delete newEvent.events;
                                return newEvent;
                            });
                            return [...all, ...events];
                        }, [])
                        .sort((a, b) => {
                            return modules.dayjs(a.start).unix() - modules.dayjs(b.start).unix();
                        });
                },
                sortert() {
                    return this.arrangement
                        .filter(item => {
                            const kat = item.category.map(cat => cat.value),
                                saler = item.events.map(event => event.hall.value);

                            const visKat =
                                this.filter.kategori.length > 0
                                    ? this.filter.kategori.some(item => kat.includes(item))
                                    : true;
                            const visSal =
                                this.filter.sal.length > 0
                                    ? this.filter.sal.some(item => saler.includes(item))
                                    : true;
                            const visMnd = this.filter.mnd
                                ? item.events.some(event =>
                                    modules
                                        .dayjs(event.start)
                                        .isSame(this.filter.mnd.toString(), 'month')
                                )
                                : true;
                            const visTekst = this.filter.tekst
                                ? (item.title + ' ' + item.subtitle)
                                .toLowerCase()
                                .indexOf(this.filter.tekst.toLowerCase()) !== -1
                                : true;

                            return visKat && visSal && visMnd && visTekst;
                        })
                        .sort((a, b) => {
                            return a.sort - b.sort;
                        })
                        .slice(0, limit);
                },
                addExtra(selector = '.arr') {
                    // console.log(this.ekstraIndex);
                    const extras = document.querySelectorAll('.extra');
                    extras.forEach(item => {
                        item.remove();
                    });
                    let linkArray = [
                        {
                            title: 'En konsert i gave?',
                            undertitle: 'Velg beløp og print selv!',
                            inverted: 0,
                            color: 'blue',
                            svg: 'diamant',
                            url: '/gavekort',
                            urlText: 'Kjøp gavekort'
                        },
                        {
                            title: 'Gavekort til en venn?',
                            undertitle: 'Velg beløp og print selv!',
                            inverted: 0,
                            color: 'blue',
                            svg: 'hullIMidten',
                            url: '/gavekort',
                            urlText: 'Kjøp gavekort'
                        },
                        {
                            title: 'Spise litt før konsert?',
                            undertitle: 'Se oversikt over restauranter',
                            inverted: 1,
                            color: 'green',
                            svg: 'diamant',
                            url: '/mat-drikke',
                            urlText: 'Se oversikt'
                        }
                    ];

                    linkArray = [...linkArray, ...linkArray];
                    const items = document.querySelectorAll(selector);
                    this.ekstraIndex.forEach((item, index) => {
                        const el = items[item];
                        if (el) {
                            const data = linkArray[index];
                            let temp = `<div class="swiper-slide !h-auto rounded-xl text-${data.color}-${data.inverted ? '500' : '700'} bg-${data.color}-${data.inverted ? '700' : '500'}">
                                <div x-cloak class="p-10 flex flex-col h-full justify-between gap-4" x-data="app.initForm(null, '${data.svg}')">
                                    <div class="relative block" x-init="svg('!text-current w-full pointer-events-none')">
                                        <div class="absolute inset-x-0 top-0 h-full overflow-hidden">
                                            <div class="font-heading leading-aLittleBittle text-7xl lg:text-9xl block opacity-0 text-white" x-intersect.once="app.resizeText($el, -1, (el) => el.classList.remove('opacity-0'))">${data.title}</div>
                                        </div>
                                    </div>
                                    <div class="text-center text-white">${data.undertitle}</div>
                                    <a href="${data.url}" class="px-3 py-2 rounded-full mx-auto text-sm bg-current">
                                        <span class="text-white">${data.title}</span>
                                    </a>
                                </div>
                            </div>`;
                            if (this.visning === 0) {
                                temp = `<li class="extra border-b-2 border-black/20 pb-6 text-${data.color}-${data.inverted ? '500' : '700'}">
									<div class="rounded-md flex flex-col md:flex-row md:justify-between gap-4 items-center bg-${data.color}-${data.inverted ? '700' : '500'} p-4 pr-6">
										<div class="flex items-center gap-4 w-full md:w-auto">
											<div x-data="app.initForm( null,'${data.svg}')" x-init="svg('!text-current w-20 pointer-events-none');"></div>
											<div class="text-white">
                                                <div class="text-xl">${data.title}</div>
                                                <div class="md:hidden">${data.undertitle}</div>
                                            </div>
										</div>
										<div class="md:flex items-center gap-10">
										    <div class="hidden md:block text-white">${data.undertitle}</div>
											<a href="${data.url}" class="px-3 py-2 rounded-full text-sm bg-current">
                                                <span class="text-white">${data.urlText}</span>
                                            </a>
										</div>
									</div>
								</li>`;
                            }

                            el.insertAdjacentHTML('afterend', temp);
                        }
                    });
                }
            };
        },
        getStil(data = 0, formasjon: number = 0, rawData: boolean = false) {
            // console.log(!Number.isInteger(data), data, formasjon);
            if (!Number.isInteger(data)) {
                const color = rawData ? data : this.getColors(data);
                return {
                    bg: color[0],
                    fg: color[1],
                    ac: color[1],
                    svgForm: this.circleShapes[formasjon]
                };
            }
            return this.mndStil[data];
        },
        getColors(colors, transmute) {
            if (isEmpty(colors)) {
                return ['#ffffff', '#000000'];
            }

            if (typeof colors === 'string') {
                colors = colors.split('|');
            }
            let bg = getClosestColor(colors[0], this.colorMatrix.bg),
                fg = getClosestColor(colors[1], this.colorMatrix.fg, bg);

            if (transmute) {
                // console.log('bg', this.colorMatrix.bg.indexOf(bg), bg);
                // console.log('fg', this.colorMatrix.fg.indexOf(fg), fg);
                const bgIndex = this.colorMatrix.bg.indexOf(bg);
                if (bgIndex % 2 === 0) {
                    bg = this.colorMatrix.bg[bgIndex + 1];
                }
                const fgIndex = this.colorMatrix.fg.indexOf(fg);
                if (fgIndex % 2 === 1) {
                    fg = this.colorMatrix.fg[fgIndex - 1];
                }
            }
            return [bg, fg];
        },
        formatDate(time, format) {
            if (!time) {
                return '';
            }
            return modules.dayjs(time).format(format);
        },
        resizeText(elm, i, callback) {
            if (this.resizeElms.indexOf(elm) === -1) {
                this.resizeElms.push(elm);
            }
            if (i < 0) {
                i = Math.floor(elm.parentNode.offsetWidth / 3);
            }
            if (i === 16) {
                return typeof callback === 'function' ? callback(elm) : true;
            }
            elm.style.fontSize = i + 'px';
            elm.style.lineHeight = i + 10 + 'px';
            setTimeout(() => {
                if (
                    elm.scrollHeight < elm.parentNode.offsetHeight / 2 &&
                    elm.scrollWidth <= elm.offsetWidth
                ) {
                    return typeof callback === 'function' ? callback(elm) : true;
                }

                this.resizeText(elm, i - 1, callback);
            }, 5);
        },
        billStatus(value) {
            let status = 'Kjøp';
            switch (value) {
                case 2:
                    status = 'Venteliste';
                    break;
                case 3:
                    status = 'Gratis';
                    break;
            }
            return status;
        },
        nyhetsbrev() {
            return {
                message: '',
                error: '',
                sending: false,
                submitForm() {
                    this.message = '';
                    this.error = '';

                    let url = new URL(this.$el.getAttribute('action'));
                    let data = new FormData(this.$el);

                    if (data.get('honey') !== '') {
                        console.error('honey felt er fylt ut');
                        return false;
                    }

                    data.delete('honey');

                    // url.search = new URLSearchParams({ ...search });
                    // console.log('SEND!', url);
                    this.sending = true;

                    fetch(url, {
                        method: 'POST',
                        body: data
                    })
                        .then(res => {
                            console.log(res);
                            // console.log(res.data);
                            if (res.ok) {
                                this.message = 'Du er påmeldt! Sjekk din e-post';
                            } else {
                                this.error = 'Ops, noe gikk feil!';
                            }
                        })
                        .catch(error => {
                            this.error = error.message;
                        })
                        .then(() => {
                            this.sending = false;
                        });
                }
            };
        },
        skjema() {
            return {
                form: null,
                success: false,
                page: 0,
                message: '',
                errors: [],
                init() {
                    this.form = this.$el.querySelector('form');
                },
                async send() {
                    const data = new FormData(this.form);

                    const action = this.form.getAttribute('action') || '/';
                    const response = await fetch(action, {
                        method: 'POST',
                        headers: {
                            'Cache-Control': 'no-cache',
                            'Accept': 'application/json',
                            'X-Requested-With': 'XMLHttpRequest',
                            'HTTP_X_REQUESTED_WITH': 'XMLHttpRequest',
                            'X-CSRF-Token': this.csrf,
                        },
                        body: data
                    });
                    if (response.ok) {
                        this.message = this.form.getAttribute('data-success-message') || null;
                        this.success = true;
                    }
                }
            }
        },
        showSubNav() {
            return {
                show: true,
                prevScrollpos: 0,
                init() {
                    window.onscroll = () => {
                        const currentScrollPos = window.pageYOffset;
                        this.show = this.prevScrollpos > currentScrollPos;
                        this.prevScrollpos = currentScrollPos;
                    }
                    window.onhashchange = () => {
                        this.goToElm();
                    }
                    this.goToElm();
                },
                goToElm(element = null) {
                    const elm = element || window.location.hash ? document.querySelector(`[data-id='${window.location.hash.replace("#", "")}']`) : null;
                    if (elm) {
                        setTimeout(() => {
                            window.scrollTo({
                                top: elm.getBoundingClientRect().top + window.pageYOffset - 120,
                                behavior: "smooth"
                            });
                        }, 100)
                    }
                }
            }
        },
        monsterTopp() {
            return {
                random: Array.from({length: 12}, (v, k) => k + 0),
                runRandom() {
                    this.random = this.random.sort(() => 0.5 - Math.random());
                },
                init() {
                    this.runRandom();
                    setInterval(() => {
                        this.runRandom();
                    }, 2000);
                }
            };
        },
        initSalKart(sal: string = null) {
            return {
                sal: sal,
                rad: null,
                sete: null,
                rader: [],
                async setup() {
                    const {default: sal} = await import(`@/svg/sal/${this.sal}.svg?raw`);
                    this.$el.innerHTML = sal;
                    const {default: tippy, hideAll} = await import('tippy.js');
                    this.$watch('rad', rad => {
                        console.log('rad', rad)
                        hideAll();
                        this.sete = null;
                        this.$el.querySelectorAll('.rad').forEach(item => {
                            const id = item.id.split('_')[1] || null;
                            if (id === rad || !rad) {
                                item.classList.remove('hide');
                            } else {
                                item.classList.add('hide');
                            }
                        })
                    })
                    this.$watch('sete', sete => {
                        console.log('sete', sete, `.rad_${this.rad}_sete_${sete}`)
                        const instance = this.$el.querySelector(`#rad_${this.rad}_sete_${sete}`)._tippy;
                        hideAll();
                        instance.show();
                        console.log(instance);
                    })
                    this.rader = Array.from(this.$el.querySelectorAll('.rad')).map(item => {
                        const id = item.id.split('_');
                        if (id[0] == 'rad') {
                            return {label: `Rad ${id[1]}`, value: parseInt(id[1])};
                        }
                    }).filter(Boolean).sort((a, b) => a.value - b.value);
                    console.log(this.rader)

                    tippy(this.$el.querySelectorAll('.sete'), {
                        theme: 'olavshallen',
                        touch: true,
                        content: 'Laster...',
                        hideOnClick: false,
                        allowHTML: true,
                        duration: 500,
                        onShow: (instance) => {
                            instance.reference.classList.add('selected')
                        },
                        onHide: (instance) => {
                            instance.reference.classList.remove('selected')
                        },
                        onCreate: (instance) => {
                            const target = instance.reference,
                                idArr = target.id.split('_'),
                                rad = idArr[1],
                                sete = idArr[3],
                                inngang = target?.dataset.inngang || target.parentElement?.dataset.inngang || null;

                            instance.setContent(`
<!--                                 <figure class="w-[140px] h-[90px]"><img data-src="${placeholder(140, 90)}" class="lazyload w-full bg-black" /></figure>-->
                                 <div class="block #my-2">Rad <strong class="font-black">${rad}</strong> • Sete <strong class="font-black">${sete}</strong></div>
                                 <div class="${!inngang ? 'hidden' : ''}">Inngang: <strong class="font-black">${inngang?.toUpperCase()}</strong></div>
                             `);
                        }
                    });
                },
                seter(rad: number = null) {
                    return Array.from(this.$root.querySelectorAll(`#rad_${rad} .sete`)).map(item => {
                        const id = item.id.split('_')[3];
                        return {label: `Sete ${id}`, value: parseInt(id)};
                    }).sort((a, b) => a.value - b.value);
                }
            }
        },
        initSlideshow(type = null, merge = {}) {
            return {
                slideshow: null,
                async init(elm = this.$el) {
                    const {Swiper, Navigation, Pagination} = await import('swiper');
                    Swiper.use([Navigation, Pagination]);

                    let options = {
                        navigation: {
                            nextEl: elm.querySelector('.swiper-button-next'),
                            prevEl: elm.querySelector('.swiper-button-prev')
                        }
                    };

                    if (type === 'utvalgte') {
                        options = Object.assign(options, {
                            slidesPerView: 'auto',
                            spaceBetween: 16,
                            breakpoints: {
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 24
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 24
                                },
                                1280: {
                                    slidesPerView: 4,
                                    spaceBetween: 24
                                }
                            }
                        });
                    } else if (type === 'rikTekst') {
                        options = Object.assign(options, {
                            slidesPerView: 'auto',
                            spaceBetween: 24,
                            breakpoints: {
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 60
                                },
                                1024: {
                                    slidesPerView: 2,
                                    spaceBetween: 120
                                },
                                1600: {
                                    slidesPerView: 2,
                                    spaceBetween: 200
                                }
                            }
                        });
                    } else if (type === 'andre') {
                        options = Object.assign(options, {
                            slidesPerView: 'auto',
                            spaceBetween: 24,
                            breakpoints: {
                                768: {
                                    slidesPerView: 2,
                                    spaceBetween: 60
                                },
                                1200: {
                                    slidesPerView: 2,
                                    spaceBetween: 120
                                }
                            }
                        });
                    } else if (type === 'module') {
                        options = Object.assign(options, {
                            autoHeight: Boolean(elm.dataset.autoheight || 0),
                            pagination: {
                                el: '.pagination',
                                type: 'fraction'
                            }
                        });
                    }
                    options = Object.assign(options, merge);

                    const swiper = new Swiper(elm, options);
                    this.$nextTick(() => {
                        swiper.update();
                    });

                    if (!!options?.autoHeight) {
                        setTimeout(() => {
                            swiper.update();
                        }, 300)
                    }

                    this.slideshow = swiper;
                }
            };
        },
        header() {
            return {
                resultat: {
                    all: [],
                    viktig: [],
                    vanlig: []
                },
                tider: {
                    vanlige: [],
                    spesielle: []
                },
                idag: null,
                visNotis: false,
                seen: localStorage.getItem('seen_notis'),
                unseen: 0,
                openMenu: false,
                openNode: -1,
                bgColor: 'bg-black',
                colors: {
                    dark: [
                        'bg-red-700',
                        'bg-green-700',
                        'bg-blue-700',
                        'bg-orange-700',
                        'bg-purple-700',
                        'bg-yellow-700'
                    ],
                    light: [
                        'bg-red-300',
                        'bg-green-300',
                        'bg-blue-300',
                        'bg-orange-300',
                        'bg-purple-300',
                        'bg-yellow-300'
                    ]
                },
                searchGroups: {
                    arrangement: {
                        header: 'Arrangement',
                        priority: 1
                    },
                    side: {
                        header: 'Informasjon',
                        priority: 2
                    },
                    artikler: {
                        header: 'Artikler',
                        priority: 4
                    },
                    leietaker: {
                        header: 'På huset',
                        priority: 3
                    },
                    personer: {
                        header: 'Personer',
                        priority: 3
                    }
                },
                searchHits: [],
                searchMode: false,
                init() {
                    this.notiser();
                    this.apningstider();
                },
                toggleNode(i) {
                    if (i === this.openNode) {
                        this.bgColor = 'bg-black';
                        this.openNode = -1;
                    } else {
                        this.setNewBgColor('dark');
                        this.openNode = i;
                    }
                },
                setNewBgColor(type) {
                    let newColor = this.bgColor;
                    while (newColor === this.bgColor) {
                        newColor =
                            this.colors[type][Math.floor(Math.random() * this.colors[type].length)];
                    }
                    this.bgColor = newColor;
                },
                vis() {
                    this.visNotis = !this.visNotis;
                    localStorage.setItem(
                        'seen_notis',
                        this.resultat.all.map(item => item.id)
                    );
                    this.unseen = 0;
                },
                async notiser() {
                    let resultat = {};
                    resultat.all = (await (await fetch('/api/notiser')).json()).data || null;
                    resultat.vanlig = resultat.all.filter(item => {
                        return item.type == 'vanlig';
                    });
                    resultat.viktig = resultat.all.filter(item => {
                        return item.type == 'viktig';
                    });
                    this.resultat = resultat;
                    this.unseen = resultat.all.filter(item => {
                        return (this.seen && !this.seen.includes(item.id)) || !this.seen;
                    }).length;
                },
                async apningstider() {
                    let aapningstider = getCache('aapningstider:fix');
                    if (!aapningstider) {
                        const seomatic = (await (await fetch('/actions/seomatic/meta-container/meta-site-vars-container?uri=/&asArray=true')).json()) || null;
                        let leietakere = (await (await fetch('/api/aapningstider')).json()).data || null;
                        console.log(leietakere);
                        const tider = seomatic.MetaSiteVarsContainer.identity.localBusinessOpeningHours;
                        this.idag = tider[modules.dayjs().format('d')];
                        let nyeTider = tider.map((tid, index) => {
                            return {
                                dag: modules.dayjs().day(index).format('ddd'),
                                tid: modules.dayjs(tid.open.date).format('HH:mm') + ' - ' + modules.dayjs(tid.close.date).format('HH:mm')
                            };
                        });
                        nyeTider.push(nyeTider.shift());
                        let groupByTid = nyeTider.reduce((group, product) => {
                            const {tid} = product;
                            group[tid] = group[tid] ?? [];
                            group[tid].push(product);
                            return group;
                        }, {});
                        groupByTid = objectMap(groupByTid, (k, v) => {
                            return {
                                "label": `${k[0].dag} - ${k.pop().dag}`,
                                "value": v
                            };
                        });
                        leietakere.vanlige.unshift({"title": "Olavshallen", "tid": groupByTid})
                        aapningstider = leietakere;
                        setCache('aapningstider:fix', aapningstider, 30 * 60);
                        //TODO: slett denne etterhvert
                        deleteCache('aapningstider');
                    }

                    this.tider = aapningstider;
                },
                async search(ev) {
                    ev.preventDefault();
                    const query = this.$refs.searchBox.value;
                    if (query.length === 0) {
                        this.searchMode = false;
                        this.searchHits = [];
                        this.bgColor = 'bg-black';
                        return false;
                    } else {
                        this.searchMode = true;
                        this.setNewBgColor('light');
                        this.searchHits = [
                            {
                                header: 'Søker ...',
                                items: []
                            }
                        ];
                    }

                    let json = (await (await fetch(`/api/sok?q=${query}`)).json()).data || null;
                    if (!json.length) {
                        this.searchHits = [
                            {
                                header: 'Ingen treff på søket',
                                items: []
                            }
                        ];
                    } else {
                        const groups = json.reduce((arr, item) => {
                            let group = arr.find(a => a.type === item.type);
                            if (!group) {
                                group = {
                                    type: item.type,
                                    items: [],
                                    ...this.searchGroups[item.type]
                                };
                                arr.push(group);
                            }
                            group.items.push(item);
                            return arr;
                        }, []);
                        groups.sort((a, b) => {
                            return a.priority - b.priority;
                        });
                        this.searchHits = groups;
                    }
                }
            };
        },
        histogram: (width: number) => {
            const columnWidth = 3;
            const columnGap = 2;
            const height = 25;
            const id = Math.floor(Math.random() * 100000);
            width = width - (width % (columnWidth + columnGap)) - columnGap;
            const columnCount = (width + columnGap) / (columnWidth + columnGap);
            const middle = Math.floor(columnCount / 2);
            const columns = [...Array(columnCount)].map(
                (_, i) =>
                    (Math.random() * ((height * 2) / 3) + height / 3) *
                    (Math.pow(middle - Math.abs(middle - i), 1.2) / middle)
            );

            return `<svg width="${width}" height="${height}" viewBox="0 0 ${width} ${height}" fill="none" xmlns="http://www.w3.org/2000/svg">
              <style>
                .hist-${id}-col { transform-origin: bottom; }
                @keyframes hist-${id} {
                    from { transform: scaleY(0.6) }
                    to { transform: scaleY(1) }
                }
                ${columns
                .map(
                    (_, i) =>
                        `.hist-${id}-${i} { animation: ${
                            Math.random() * 0.6 + 0.3
                        }s linear infinite alternate hist-${id} }`
                )
                .join('\n')}
              </style>
              ${columns
                .map(
                    (c, i) =>
                        `<rect class="hist-${id}-col hist-${id}-${i}" x="${
                            i * (columnWidth + columnGap)
                        }" y="${height - c}" width="3" height="${c}" fill="currentcolor"/>`
                )
                .join('\n')}
            </svg>`;
        }
    };

    let throttle, prevWidth;
    window.addEventListener('resize', () => {
        if (prevWidth === window.innerWidth) {
            return;
        }
        prevWidth = window.innerWidth;
        clearTimeout(throttle);
        throttle = setTimeout(() => {
            window.app.resizeElms.forEach(elm => {
                elm.style.opacity = 0;

                window.app.resizeText(elm, -1, () => {
                    elm.style.opacity = '';
                });
            });
        }, 200);
    });

    Alpine.start();

    document.querySelectorAll('.fit').forEach((elm: HTMLElement) => {
        modules.fitty(elm);
    });
});
